import MerchantService from "./MerchantAPIService";



class LogService extends MerchantService
{
    addLog = async ( logs:any, data:any = []) => {
        const result = await this.post({logs}, '/logs');
          
        return result ? result.data : null;
   
     }
}

export default LogService