import React, { Component, useState } from "react";
import "./App.css";
import axios from "axios";
import Loader from "./components/Loader";
import LayoutView from "./modules/layout/LayoutView";
import Notification from "./modules/layout/Notification";
import lazyComponentLoader from "./hoc/LazyLoader";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { isArray } from "util";
import DialogUtils from "./utils/DialogUtils";
import ProtectedRoute from "./hoc/ProtectedRoute";
import UnprotectedRoute from "./hoc/UnprotectedRoute";
import APPConfig from "./config";

//Redux
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import { createStore, compose, applyMiddleware } from "redux";
import reducers from "./utils_redux/reducers";
import CreateOnBoardingAccount from "./modules/onboarding-account/CreateOnBoardingAccount";
import ReauthOnBoardingAccount from "./modules/onboarding-account/ReauthOnBoardingAccount";
import SuccessOnBoardingAccount from "./modules/onboarding-account/SuccessOnBoardingAccount";

let composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(reduxThunk))
);

interface IAppState {
    isLoading: Boolean;
    errorVisible: Boolean;
    notifiPopup: boolean;
    orderCount: Number;
    onClose: Function;
}

//include modules

const Login: any = lazyComponentLoader(
    () => import("./modules/auth/login/Login")
);
const Forgot: any = lazyComponentLoader(
    () => import("./modules/auth/forgot-password/ForgotPassword")
);
const Reset: any = lazyComponentLoader(
    () => import("./modules/auth/forgot-password/ResetPassword")
);
const Register: any = lazyComponentLoader(
    () => import("./modules/auth/register/RegisterView")
);
const Dashboard: any = lazyComponentLoader(
    () => import("./modules/dashboard/DashboardView")
);
const RestaurantDetail = lazyComponentLoader(
    () => import("./modules/restaurant-detail/RestaurantDetailManagementView")
);
const MenuManagement = lazyComponentLoader(
    () => import("./modules/menu-management/MenuManagementView")
);
const PickupManagement = lazyComponentLoader(
    () => import("./modules/pickup-management/PickupManagementView")
);
const DineManagement = lazyComponentLoader(
    () => import("./modules/dinein-management/DineinManagementView")
);
const DeliveryManagement = lazyComponentLoader(
    () => import("./modules/delivery-management/DeliveryManagementView")
);
const OrderManagement = lazyComponentLoader(
    () => import("./modules/order-management/OrderManagementView")
);
const OrderHistoryManagement = lazyComponentLoader(
    () =>
        import("./modules/sales-history-management/SalesHistoryManagementView")
);
const ReceivableManagement = lazyComponentLoader(
    () => import("./modules/receivable-management/ReceivableManagementView")
);
const AboutUsView = lazyComponentLoader(
    () => import("./modules/about-us/AboutUsView")
);
const OrderManagementOld = lazyComponentLoader(
    () => import("./modules/order-management/OrderManagementViewOld")
);

const ProfileManagement = lazyComponentLoader(
    () => import("./modules/profile/ProfileManagementView")
);

class App extends Component<any, IAppState> {
    constructor(props: any) {
        super(props);

        const self = this;
        const onClose = () => {
            self.setState({ notifiPopup: false });
        };

        //initialize state here
        this.state = {
            isLoading: false,
            errorVisible: true,
            notifiPopup: true,
            orderCount: 0,
            onClose: onClose,
        };

        axios.interceptors.request.use(
            function (config) {
                // spinning start to show
                self.setState({ isLoading: true });
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            function (response) {
                // spinning hide
                self.setState({ isLoading: false });

                return response;
            },
            async (error) => {
                if (error.response) {
                    if(error.response.data.errors.message === "Stripe Onboarding"){
                        window.location.href = '/onboarding';
                    }else{
                        this.showError(error.response.data.errors);
                    }
                } else {
                    this.showError(error);
                }
                self.setState({ isLoading: false });
                return Promise.reject(error);
            }
        );
    }

    showError(errors: any) {
        let content = null;
        if (isArray(errors)) {
            content = (
                <div>
                    {errors.map((item: any, index: number) => {
                        return (
                            <div key={Math.random().toString(6)}>
                                {item.message.includes("timeout") || item.message.includes("access_token") ? "Your session has timed out." : item.message}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            content = (
                <div>
                    {errors.message.includes("Network Error")
                        ? "No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again."
                        : errors.message}
                </div>
            );
        }

        DialogUtils.error(content, (content: any)=> {
            if(content && content.props && content.props.children)
            {
                if(content.props.children.includes("Unable to transfer")){
                    window.location.reload()
                }
            }
        });
    }

    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Provider store={store}>
                            <LayoutView>
                                <Notification />
                                <UnprotectedRoute
                                    exact
                                    path="/login"
                                    component={Login}
                                />
                                <UnprotectedRoute
                                    exact
                                    path="/forgot-password"
                                    component={Forgot}
                                />
                                <UnprotectedRoute
                                    exact
                                    path="/reset-password"
                                    component={Reset}
                                />
                                <UnprotectedRoute
                                    exact
                                    path="/register"
                                    component={Register}
                                />
                                <UnprotectedRoute
                                    exact
                                    path="/about"
                                    component={AboutUsView}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/"
                                    component={RestaurantDetail}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard"
                                    component={Dashboard}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/restaurant_detail"
                                    component={RestaurantDetail}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/menu"
                                    component={MenuManagement}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/pickup"
                                    component={PickupManagement}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/reservations"
                                    component={DineManagement}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/delivery"
                                    component={DeliveryManagement}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/orders"
                                    component={OrderManagement}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/sales_history"
                                    component={OrderHistoryManagement}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/receivable"
                                    component={ReceivableManagement}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/profile"
                                    component={ProfileManagement}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/onboarding"
                                    component={CreateOnBoardingAccount} 
                                />
                                <ProtectedRoute
                                    exact
                                    path="/onboarding/reauth"
                                    component={ReauthOnBoardingAccount} 
                                />
                                <ProtectedRoute
                                    exact
                                    path="/onboarding/success"
                                    component={SuccessOnBoardingAccount} 
                                />
                            </LayoutView>
                        </Provider>
                    </Switch>
                </Router>

                {this.state.isLoading ? <Loader /> : null}
            </div>
        );
    }
}

export default App;
