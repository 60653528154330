import React, { Component, RefObject } from "react";
import NotificationView from "../notification/orderNotification";
import { withRouter } from "react-router-dom";
import APPConfig from "../../config";
import { Howl, Howler } from "howler";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import moment from "moment";

//notification
const notificationAudio = require("../../assets/audio/notificationAudio.mp3");

//socket
var socket = require("socket.io-client")(APPConfig.SOCKET_URL, {
    transports: ["websocket", "polling", "flashsocket"],
    reconnection: true, // Enable reconnection
    reconnectionDelay: 1000, // Delay between reconnection attempts in milliseconds
    reconnectionDelayMax : 5000, // Maximum delay between reconnection attempts
    reconnectionAttempts: 5,// Number of reconnection attempts
    randomizationFactor: 0.5, // Randomization factor to add randomness to reconnection delay
    connect_timeout: 5000, // Connection timeout duration in milliseconds
    timeout: 10000, // Timeout for general event callbacks in milliseconds
});


class Notification extends Component<any> {
    private buttonRef: RefObject<HTMLButtonElement>;
    constructor(props: any) {
        super(props);
        this.buttonRef = React.createRef();
        // this.initialize();
        this.onConnect();
    }

    initialize = () => {
        Howler.mute(true);
        this.playSound();
    }

    SoundStop = () => {
        // this.state.soundHowler.stop();
        Howler.unload()
    }

    onUnload = (e:any)=> { // the method that will be used for both add and remove event
        // socket.close();
     }

    componentDidMount() {
        // Howler.autoSuspend = false;
        Howler.autoUnlock = true;
        Howler.html5PoolSize=100; // It's because I play a lot of sounds
        this.setState({ reRun: 0 });
        
        window.addEventListener("beforeunload", this.onUnload);
    }

    componentWillUnmount() {
        Howler.autoUnlock = true;
        Howler.html5PoolSize=100; // It's because I play a lot of sounds        
        // Howler.autoSuspend = false;
        window.removeEventListener("beforeunload", this.onUnload);
    }

    componentDidUpdate(preProps: any, prevState: any) {
        if (prevState.reRun !== this.state.reRun) {
            this.SoundCreate()
        }
    }

    onClose = () => {
        this.setState({ notifiPopup: false });
        this.SoundStop();
        this.props.history.push("/orders");
    };

    state = {
        // audio: new Audio(notificationAudio),
        reRun: 0,
        notifiPopup: false,
        orderCount: 0,
        onClose: this.onClose,
        audioContextCreated: false
    };

    // SoundCreate = async() => {
    //     this.state.soundHowler.stop();
    //     Howler.unload();
    //     await this.setState(
    //         {
    //             soundHowler : new Howl({
    //                 src: [notificationAudio],
    //                 loop: true,
    //                 volume: 1.0,
    //                 html5: true
    //             }),
    //         }
    //     )
    //     this.state.soundHowler.play();
    //     Howler.volume(1.0)
    // };

    SoundCreate = async() => {
        Howler.unload()
        if (!this.state.audioContextCreated) {
            // If AudioContext is not created or resumed, initialize it within a user-triggered event
            Howler.autoUnlock = true; // Unlock audio on mobile devices
            Howler.mute(false); // Unmute audio
      
            // Create or resume the AudioContext within the user-initiated event (click)
            Howler.ctx.resume().then(() => {
              this.setState({ audioContextCreated: true });
              this.playSound();
            }).catch((error) => {
            });
          } else {
            // If AudioContext is already created, directly play the sound
            this.playSound();
        }
    }

    playSound = () => {
        // Your code to play the sound using Howler
        // Howler.unload();
        let soundHowler  = new Howl({
            src: [notificationAudio],
            loop: true,
            volume: 1.0,
            html5: true
        })
        Howler.volume(1.0)
        soundHowler.once('load', () => {
            soundHowler.play();
        });
        soundHowler.once('loaderror', () => {
            this.SoundCreate()
          });
      
          soundHowler.once('playerror', () => {
            this.SoundCreate()
        });
    };
      
    // audioRestart = () => {
    //     this.state.audio.pause();
    //     this.state.audio.currentTime = 0;
    //     this.state.audio.play();
    // };

    onConnect = () => {
        // const MINUTE_MS = 1000 * 60 * 60;
        const MINUTE_MS = 300000;
        const storeString = localStorage.getItem("store")
            ? localStorage.getItem("store")
            : "{}";
        const storObj = JSON.parse(storeString ? storeString : "");
        const storeID = storObj && storObj._id ? storObj._id : null;
        if (storeID) {
            //socket
            socket.on(storeID + "_notification", (data: any) => {
                // this.SoundStop();
                let num = Math.floor(Math.random() * 100 + 1);
                this.setState({ reRun: this.state.reRun + num, orderCount: data, notifiPopup: true });
                this.onPageHandleSound();
                this.setNotification();
        
            });

            socket.on('connect', function() {            
            });

            const interval = setInterval(() => {
                socket.emit(storeID + "_notification", "hi server")
            }, MINUTE_MS);
                 

            socket.on('disconnect', function () {
                //do stuff
                // socket.close();
                // window.location.reload();
                
            });
            socket.on('reconnect', (attemptNumber:any) => {
            });
            socket.on("connect_error", (data:string) => {
                // socket.close();
                // window.location.reload(); 
            });
            socket.on('reconnect_attempt', (attemptNumber:any) => {
              });
          
            socket.on('reconnect_error', (error:any) => {
            });
          
            socket.on('reconnect_failed', () => {
            });

             // Clean up the interval when the component unmounts
            return () => {
                clearInterval(interval);
                socket.disconnect(); // Disconnect the socket when the component unmounts
            };
            
        }
    };

    setReconnect = () => {
        const storeString = localStorage.getItem("store")
            ? localStorage.getItem("store")
            : "{}";
        const storObj = JSON.parse(storeString ? storeString : "");
        const storeID = storObj && storObj._id ? storObj._id : null;
        socket.on(storeID + "_notification", (data: any) => {
            let num = Math.floor(Math.random() * 100 + 1);
            this.setState({ reRun: this.state.reRun + num });
            this.SoundCreate();
            this.setState({ orderCount: data });
            this.setState({ notifiPopup: true });
            this.setNotification();
        });
    }
    
    setNotification = () => {
        localStorage.setItem("store_notification", "1");
    };

    onPageReload = () => {
        // socket.close();
        window.location.reload();
    }
     

    onPageHandleSound = () => {
        if (this.buttonRef.current) {
            this.buttonRef.current.click();
        }
    }
    
    

    render() {
        // Howler.volume(1.0);

        return (
            <>
            {/* <button ref={this.buttonRef} className="sound_btn" id="notification_sound_btn" onClick={this.SoundCreate}>start</button> */}
{/* <button onClick={this.onPageHandleSound}>kni</button> */}
            <div id="notification_container">
                <NotificationView
                    visible={this.state.notifiPopup}
                    onClose={this.state.onClose}
                    orderCount={this.state.orderCount}
                />
            </div>
            </>
        );
    }
}

export default withRouter(Notification);
