import APIService from "./APIService";

class MerchantAPIService extends APIService
{

    constructor (){
        super()
        this.baseURL +="/merchants"
    }
    
}

export default MerchantAPIService