import { combineReducers } from "redux";

let initialState = {};

const orderReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SEND_ORDER_DETAILS":
            return { ...state, ...action.payload };
        case "RESET":
            return initialState;
        default:
            return state;
    }
};

const ipReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SEND_IP_ADDRESS":
            return { ...state, ...action.payload };
        case "RESETIP":
            return initialState;
        default:
            return state;
    }
};

export default combineReducers({
    orderReducer: orderReducer,
    ipReducer: ipReducer,
});
