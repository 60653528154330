import MerchantService from "./MerchantAPIService";

class OnboardingCustomerService extends MerchantService
{
   createLink  = async () => {
      const result = await this.get('onboarding');
      return result ? result.data : null;
   }

   createLinkExistingAccount  = async () => {
      const result = await this.get('onboarding/create');
      return result ? result.data : null;
   }

   checkSuccess  = async () => {
      const result = await this.get('onboarding/success');
      return result ? result.data : null;
   }
   checkHasOnboarding  = async () => {
      const result = await this.get('onboarding/hasOnboarding');
      return result ? result.data : null;
   }

   linkExistingAccount  = async (code:string, state: string) => {
      const result = await this.get('onboarding/link?code=' + code + '&state=' + state);
      return result ? result.data : null;
   }

}

export default OnboardingCustomerService