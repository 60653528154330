import React, { Component } from "react";
import { Layout } from "antd";
import HeaderView from "./HeaderView";
import NewHeaderView from "./NewHeaderView";
import SideMenuView from "./SideMenuView";
import OnboardingCustomerService from "../../services/api/OnboardingCustomerService";

const { Sider, Content } = Layout;
interface ILayoutViewProps {
    orders?: any | null;
}

let onboardingCustomerService = new OnboardingCustomerService();
class LayoutView extends Component<ILayoutViewProps> {
    state = {
        collapsed: false,
    };

    toggleCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    componentDidMount = async() => {
        if(localStorage.getItem("access_token") ){
            let data = await onboardingCustomerService.checkHasOnboarding();
            if(!data && !data.details || !data.details.hasOnboarding){
                if(!window.location.pathname.includes("onboarding") ){
                    window.location.href = "/onboarding"
                }
            }
        }
    }

    render() {
        return (
            <div>
                {localStorage.getItem("access_token") ? (
                    <Layout>
                        <Layout>
                            <Layout>
                                {!window.location.pathname.includes("onboarding") && <NewHeaderView />}
                                <Content
                                    style={{
                                        padding: 12,
                                        backgroundColor: "white",
                                    }}
                                    id="main_content"
                                    className={ !window.location.pathname.includes("onboarding") ? "ant-layout-content-gray" : ""}
                                >
                                    {this.props.children}
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>
                ) : (
                    <Layout>
                        <NewHeaderView />

                        <Content
                            style={{
                                padding: 12,
                                minHeight: "80vh",
                                backgroundColor: "white",
                            }}
                            className="ant-layout-content-white"
                        >
                            {this.props.children}
                        </Content>
                    </Layout>
                )}
            </div>
        );
    }
}

export default LayoutView;
