import MerchantService from "./MerchantAPIService";



class SettingsService extends MerchantService
{
   getSettings = async () => {
      const result = await this.get('settings');
      return result ? result.data : null;
  }

  editSettings = async (settings: any = [], id:string) => {
      const result = await this.put({settings}, 'settings/' + id);
      return result ? result.data : null;
   }

   editPrinterSettings = async (settings: any = [], id:string) => {
      const result = await this.put({settings}, 'settings/printer/' + id);
      return result ? result.data : null;
   }

   setChowlyApiKey = async (api_key: string, store_id: string, chowly_status: boolean) => {
      const result = await this.put({api_key, store_id, chowly_status}, 'settings/chowly/api-key');
      return result ? result.data : null;
   }

   deleteStripeApiKey = async () => {
      const result = await this.put({}, 'settings/stripe/account');
      return result ? result.data : null;
   }
}

export default SettingsService