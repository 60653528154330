
/**
 * App configuration
 */
class APPConfig
{
    /**
     * api url endpoint
     */
    static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000/api';
    static SOCKET_URL:string = process.env.REACT_APP_API_SOCKET_URL ? process.env.REACT_APP_API_SOCKET_URL : 'http://localhost:3000';
    static DEFAULT_TIMEZONE:string = process.env.REACT_APP_DEFAULT_TIMEZONE ? process.env.REACT_APP_DEFAULT_TIMEZONE : 'America/New_York';
    static BUGSNAG_APIKEY:string = process.env.REACT_APP_BUGSNAG_APIKEY? process.env.REACT_APP_BUGSNAG_APIKEY : '';
    static CUSTOMER_ENDPOINT:string = process.env.REACT_APP_CUSTOMER_ENDPOINT? process.env.REACT_APP_CUSTOMER_ENDPOINT : '';
    static LOGO: string = process.env.REACT_APP_LOGO? process.env.REACT_APP_LOGO : "";
    static IS_ENABLE_PRINTING:string = process.env.REACT_APP_IS_ENABLE_PRINTING? process.env.REACT_APP_IS_ENABLE_PRINTING : 'false';
}

export default APPConfig;