import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const UnprotectedRoute = ({component:Component, ...rest}:any) => {
    return (
        <Route
            {...rest}
            render={props => 
                {
                    if(localStorage.getItem('access_token')) {
                        return <Redirect to={
                            {
                                pathname:'/',
                                state:{},
                            }
                        }/>
                    }
                    else{
                        return <Component {...props} />
                    }
                }
            }
        />

    );
}

export default UnprotectedRoute;