import React, { useEffect } from 'react';
import { Modal, Button } from 'antd';

interface notificationPopupProps {
    visible?:boolean,
    onClose:Function,
    orderCount?:number | null
}

const notificationPopup = (props:notificationPopupProps) => {


    const viewOrder = () => {
        props.onClose();
    }

    return (
        <div>
           <Modal
                forceRender
                title="Order Notification"
                visible={props.visible}
                centered
                className="form_modal"
                cancelText="Cancel"
                width={550}
                footer={[
                    <div className="notification_btn" key="1"  >
                        <Button onClick={viewOrder} type="primary">
                            Confirm Orders
                        </Button>
                    </div>,
                  ]}
                closable={false}
                maskClosable={false}>
                    <div className="notification">
                        <div className="notification_circle">
                            <div className="notification_ctr">
                                {props.orderCount && props.orderCount  > 0? props.orderCount : 1}
                            </div>
                            <div>
                                {props.orderCount && props.orderCount > 1? "Orders" : "Order"}
                            </div>
                        </div>
                    </div>
            </Modal>
        </div>
    );
}



export default notificationPopup