import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Menu } from "antd";
import config from "./../../config"; 
import {
    LogoutOutlined,
    SettingOutlined,
    BarsOutlined,
    PrinterOutlined,
    ShopOutlined,
    OrderedListOutlined,
    CopyOutlined,
    UserOutlined
} from "@ant-design/icons";
import SettingsView from "./Settings/Settings";
import PrinterSettingsView from "./Settings/PrinterSettingsView";
import "./less/_main-dir.less";
import GenereateOrderNowView from "./Generate/GenereateOrderNowView";
//service
import LogService from "../../services/api/LogService";
//redux
import { orderReset } from "../../utils_redux/actions";
import { connect } from "react-redux";

const { SubMenu } = Menu;
var FontAwesome = require("react-fontawesome");

const NewHeaderView = (props: any) => {
    const history = useHistory();
    const [orderDetails, setOrderDetails] = useState<any | null>();
    const [store, setStore] = useState("");
    const [user, setUser] = useState({ first_name: "", last_name: "" });
    const [settingsModalVisible, setsettingsModalVisible] = useState(false);
    const [printerSettingsModalVisible, setPrinterSettingsModalVisible] = useState(false);
    const [generateModalVisible, setGenerateModalVisible] = useState(false);
    //service
    const logService = new LogService();
    useEffect(() => {
        let storeString: string | null = localStorage.getItem("store")
            ? localStorage.getItem("store")
            : "{}";
        const storObj = JSON.parse(storeString ? storeString : "");
        let usertring: string | null = localStorage.getItem("user")
            ? localStorage.getItem("user")
            : "{}";
        const userObj = JSON.parse(usertring ? usertring : "");

        if (storObj) {
            setStore(storObj.name);
        }

        if (userObj) {
            setUser(userObj);
        }
    }, [window.location.href]);

    const onLogout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("store");
        window.location.href = "/";
    };

    const onClickSettings = () => {
        setsettingsModalVisible(true);
    };

    const onClickGenerate = () => {
        setGenerateModalVisible(true);
    }

    const onClickPrinterSettings = () => {
        setPrinterSettingsModalVisible(true);
    };

    const onClose = () => {
        setsettingsModalVisible(false);
        setGenerateModalVisible(false);
    };

    const onPrinterSettingsClose = () => {
        setPrinterSettingsModalVisible(false);
    };

    const managementName = () => {
        if (window.location.pathname === "/orders") {
            return "Order Management";
        } else if (window.location.pathname === "/menu") {
            return "Menu";
        } else if (
            window.location.pathname === "/restaurant_detail" ||
            window.location.pathname === "/"
        ) {
            return "Restaurant Details";
        } else if (window.location.pathname === "/reservations") {
            return "Reservations";
        } else if (window.location.pathname === "/sales_history") {
            return "Sales Order History";
        } else if (window.location.pathname === "/receivable") {
            return "Receivables";
        } else if (window.location.pathname === "/profile") {
            return "Profile";
        } else {
            return null;
        }
    };

    const menu = async (data: any) => {
        if (
            data.key !== "orders" &&
            data.key !== "settings" &&
            data.key !== "printersettings"
        ) {
            props.orderReset();
        }

        switch (data.key) {
            case "restaurant_details":
                history.push("/restaurant_detail");
                break;

            case "menu":
                history.push("/menu");
                break;

            case "reservations":
                history.push("/reservations");
                break;

            case "orders":
                history.push("/orders");
                break;

            case "sales_order_history":
                history.push("/sales_history");
                break;
            case "receivables":
                history.push("/receivable");
                break;
            case "profile":
                history.push("/profile");
                break;
            case "generateordernow":
                await logService.addLog([{action: "Load Generate Order Now", data: {}}])
                onClickGenerate();
                break;
            case "settings":
                onClickSettings();
                break;
            case "printersettings":
                await logService.addLog([{action: "Load Printer Setting", data: {}}])
                onClickPrinterSettings();
                break;
            case "logout":
                onLogout();
            default:
                break;
        }
    };

    return (
        <div className="main_header">
            <div className="header_web_2">
                {localStorage.getItem("access_token") ? (
                    <div className="header_2">
                        <div className="left_header_view">
                            <div className="menu_bar">
                                <Menu mode="horizontal" onClick={menu}>
                                    <SubMenu
                                        className="modified_item"
                                        key="sub1"
                                        icon={<BarsOutlined />}
                                    >
                                        <Menu.ItemGroup title={store}>
                                            <Menu.Item
                                                className="modified-item"
                                                key="restaurant_details"
                                            >
                                                <div>
                                                    <ShopOutlined />
                                                    <span>
                                                        Restaurant Details
                                                    </span>
                                                </div>{" "}
                                            </Menu.Item>
                                            <Menu.Item
                                                className="modified-item"
                                                key="menu"
                                            >
                                                <div>
                                                    <FontAwesome name="tasks" />
                                                    <span className="menu_header_title">
                                                        Menu
                                                    </span>
                                                </div>{" "}
                                            </Menu.Item>
                                            <Menu.Item
                                                className="modified-item"
                                                key="reservations"
                                            >
                                                <div>
                                                    <FontAwesome name="cutlery" />{" "}
                                                    <span className="menu_header_title">
                                                        Reservations
                                                    </span>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item
                                                className="modified-item"
                                                key="orders"
                                            >
                                                <div>
                                                    <OrderedListOutlined />{" "}
                                                    <span>Orders</span>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item
                                                className="modified-item"
                                                key="sales_order_history"
                                            >
                                                <div>
                                                    <FontAwesome name="reorder" />{" "}
                                                    <span className="menu_header_title">
                                                        Sales Order History
                                                    </span>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item
                                                className="modified-item"
                                                key="receivables"
                                            >
                                                <div>
                                                    <FontAwesome name="money" />
                                                    <span className="menu_header_title">
                                                        Receivables
                                                    </span>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item
                                                className="modified-item"
                                                key="generateordernow"
                                            >
                                                <div>
                                                    <CopyOutlined />
                                                    <span>
                                                        Generate Order Now
                                                    </span>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item
                                                className="modified-item"
                                                key="settings"
                                            >
                                                <div>
                                                    <SettingOutlined />{" "}
                                                    <span>Settings</span>
                                                </div>
                                            </Menu.Item>
                                            {config.IS_ENABLE_PRINTING && config.IS_ENABLE_PRINTING == 'true' ? (
                                                <Menu.Item
                                                    className="modified-item"
                                                    key="printersettings"
                                                >
                                                    <div>
                                                        <PrinterOutlined />
                                                        <span>
                                                            Printer Settings 
                                                        </span>
                                                    </div>
                                                </Menu.Item>
                                            ):(null)}
                                            <Menu.Item
                                                className="modified-item"
                                                key="profile"
                                            >
                                                <div>
                                                    <UserOutlined />
                                                    <span>
                                                        Profile
                                                    </span>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item
                                                className="modified-item"
                                                key="logout"
                                            >
                                                <div>
                                                    <LogoutOutlined />
                                                    <span>Log out</span>
                                                </div>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </SubMenu>
                                </Menu>
                            </div>
                            <div className="management_name">
                                <div>{managementName()}</div>
                            </div>
                        </div>
                        {window.location.pathname === "/orders" ? (
                            <div className="delivery_order_type">
                                {props.orderDetails.order_Type} Order Details
                            </div>
                        ) : (
                            ""
                        )}

                        <div className="header__right">
                            <div className="user_tab">
                                <div className="appetit_logo">
                                    <img
                                        src="img/logo.png"
                                        alt="appetit logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="header">
                        <div className="header__left">
                            <div className="header_tab extra"></div>
                            <div className="header__logo">
                                <a href="/">
                                    <img
                                        src="img/logo.png"
                                        alt="appetit logo"
                                    />
                                </a>
                                <div></div>
                            </div>
                            <div className="header_tab">
                                <a href="/about">About</a>
                            </div>
                        </div>

                        <div className="header__right">
                            <div className="header_tab">
                                <span>
                                    <a
                                        href={
                                            process.env
                                                .REACT_APP_FE_CUSTOMER_URL
                                        }
                                    >
                                        {" "}
                                        Book a reservation or place an order
                                        now!{" "}
                                    </a>
                                </span>
                            </div>
                            <div className="header_tab extra"></div>
                        </div>
                    </div>
                )}
            </div>
            <SettingsView visible={settingsModalVisible} onCancel={onClose} />
            <PrinterSettingsView
                visible={printerSettingsModalVisible}
                onCancel={onPrinterSettingsClose}
            />
            <GenereateOrderNowView visible={generateModalVisible} onCancel={onClose}></GenereateOrderNowView>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        orderDetails: state.orderReducer,
    };
};

const HeaderView = withRouter(NewHeaderView);
export default connect(mapStateToProps, { orderReset })(HeaderView);
