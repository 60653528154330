import React, { useEffect, useState } from "react";
import { Form, Modal, Tabs, Checkbox, Input, Button, Switch } from "antd";
import BaseInput from "../../../components/BaseInput";
import SettingsService from "../../../services/api/SettingsService";
import { async } from "q";
import DialogUtils from "../../../utils/DialogUtils";
const { TabPane } = Tabs;

interface SettingsViewProps {
    visible?: boolean;
    onCancel: Function;
}

const SettingsView = (props: SettingsViewProps) => {
    let [form] = Form.useForm();
    const settingService = new SettingsService();
    const [settings, setSettings] = useState<any>();
    const [settings_id, setSettingsID] = useState<any>();
    const [resto_type, setRestoType] = useState("");
    const [api_key, setAPIKey] = useState("");
    const [chowly_status, setChowlyStatus] = useState(false);
    let [securityKey, setSecurityKey] = useState("");

    useEffect(() => {
        const initialize = async () => {
            if (props.visible) {
                let result = await settingService.getSettings();
                if (result) {
                    setSettings(result);
                    setSettingsID(result._id);
                    setChowlyStatus(result.store_id.enabled_chowly ? result.store_id.enabled_chowly : false);
                    setAPIKey(result.store_id.chowly_api_key ? result.store_id.chowly_api_key : "")
                    if (result.payment_gateway_security_key) {
                        form.setFieldsValue({
                            payment_gateway_security_key:
                                result.payment_gateway_security_key,
                        });
                        setSecurityKey(result.payment_gateway_security_key);
                    }

                    let type = "restaurant";
                    if (!result.store_id.type) {
                        let storeString: string | null = localStorage.getItem(
                            "store"
                        )
                            ? localStorage.getItem("store")
                            : "{}";
                        const storObj = JSON.parse(
                            storeString ? storeString : ""
                        );
                        if (storObj.type) {
                            type = storObj.type;
                        }
                    }

                    setRestoType(
                        result.store_id.type ? result.store_id.type : type
                    );
                }
            }
        };
        initialize();
    }, [props.visible]);

    const onCancel = () => {
        props.onCancel();
        form.resetFields();
    };

    const saveAPIKey = async () => {
        let resp = await settingService.setChowlyApiKey(api_key, settings.store_id._id, chowly_status);
        let store = localStorage.getItem("store") ? localStorage.getItem("store") : "{}"
        if (store) {
            let _store = JSON.parse(store)
            _store.enabled_chowly = chowly_status
            localStorage.setItem("store", JSON.stringify(_store))
            props.onCancel();
        }
    };

    const onClick = async (type: any, field?: any) => {
        settings.isChangeKey = false;
        switch (type) {
            case "pick-up":
                settings.pickup.include_processing_fee =
                    !settings.pickup.include_processing_fee;
                break;
            case "dine-in":
                settings.dine_in.include_processing_fee =
                    !settings.dine_in.include_processing_fee;
                break;
            case "delivery":
                if (field === "processing_fee") {
                    settings.delivery.include_processing_fee =
                        !settings.delivery.include_processing_fee;
                } else {
                    settings.delivery.include_service_fee =
                        !settings.delivery.include_service_fee;
                }
                break;
        }
        if (settings_id) {
            let result = await settingService.editSettings(
                settings,
                settings_id
            );
            if (result) {
                setSettings(result);
            }
        }
    };

    const setChowlyStatusBtn = () => {
        if(!chowly_status){
            DialogUtils.confirm("Enabling Chowly Settings will remove your access to your categories and menu items. Once confirmed, Chowly can now control your categories and menu. Reverting changes will result to data loss. Do you want to continue?", 
            ()=>{
                setChowlyStatus(!chowly_status);
            })
        }else{
            setChowlyStatus(!chowly_status);
        }
        
    }
    const submintPaymentSetting = async (data: any) => {
        settings.payment_gateway_security_key =
            data.payment_gateway_security_key;

        if (!data.payment_gateway_security_key) {
            form.setFields([
                {
                    name: "payment_gateway_security_key",
                    errors: ["Stripe Account ID is required."],
                },
            ]);
        } else {
            settings.store_id = {_id: settings.store_id._id}
            settings.isChangeKey = true;
            if (settings_id) {
                let result = await settingService.editSettings(
                    settings,
                    settings_id
                );
                if (result) {
                    if (result.payment_gateway_security_key) {
                        form.setFieldsValue({
                            payment_gateway_security_key:
                                result.payment_gateway_security_key,
                        });
                        setSecurityKey(result.payment_gateway_security_key);
                    }
                    success("Stripe Account ID has been successfully saved.");
                }
            }
        }
    };

    const success = (content: any, onOk?: Function) => {
        Modal.success({
            centered: true,
            content: content,
            onOk: () => {
                if (onOk) onOk();
            },
        });
    };
    
    const onDelete = async () => {
        let result = await settingService.deleteStripeApiKey();
        if(result){
            let userString: string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
            const userObj = JSON.parse(userString ? userString : '');
            userObj.onboardingAccounts = "";
            await localStorage.setItem('user', JSON.stringify(userObj));
            const baseUrl = new URL(document.baseURI).origin;
            window.location.href = baseUrl + "/onboarding";
        }
    };

    return (
        <div>
            <Modal
                forceRender
                title="Settings"
                visible={props.visible}
                centered
                className="form_modal"
                onCancel={onCancel}
                width={550}
                footer={false}
                maskClosable={false}
            >
                {resto_type ? (
                    <div className="settings">
                        {resto_type !== "liquor" ? (
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Payment Gateway" key="1">
                                    <Form
                                        form={form}
                                        onFinish={submintPaymentSetting}
                                    >
                                        <Form.Item
                                            label="Stripe Account ID"
                                            name="payment_gateway_security_key"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                min={1}
                                                placeholder="Stripe Account ID"
                                                disabled={
                                                    securityKey.length
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Form.Item>
                                    </Form>
                                    {/* Delete Stripe Implementation */}
                                    <div className="flex-direction-column justify-content-flex-end padding-top-30px">
                                        <Button className="main-primary-btn btn-width-130px" type="primary"  onClick={() => {
                                                DialogUtils.confirm(
                                                    "Are you sure you want to delete this stripe account ID? Deleting it will redirect you to the onboarding process.",
                                                    () => {
                                                        onDelete();
                                                    }
                                                );
                                            }}>
                                        Delete
                                        </Button>
                                    </div>
                                </TabPane>
                                {/* <TabPane tab="Dine-in" key="2">
                                    <Checkbox
                                        onClick={() => {
                                            onClick("dine-in");
                                        }}
                                        checked={
                                            settings
                                                ? settings.dine_in
                                                      .include_processing_fee
                                                : false
                                        }
                                        disabled
                                    >
                                        Build processing fee (4%) into menu
                                        pricing.
                                    </Checkbox>
                                </TabPane> */}
                                <TabPane tab="Pick up" key="2">
                                    <Checkbox
                                        onClick={() => {
                                            onClick("pick-up");
                                        }}
                                        checked={
                                            settings
                                                ? settings.pickup
                                                      .include_processing_fee
                                                : false
                                        }
                                        disabled
                                    >
                                        Build processing fee (10%) into menu
                                        pricing.
                                    </Checkbox>
                                </TabPane>
                                <TabPane tab="Delivery" key="3">
                                    <div className="content">
                                        <span style={{ paddingBottom: "5px" }}>
                                            <Checkbox
                                                onClick={() => {
                                                    onClick(
                                                        "delivery",
                                                        "processing_fee"
                                                    );
                                                }}
                                                checked={
                                                    settings
                                                        ? settings.delivery
                                                              .include_processing_fee
                                                        : false
                                                }
                                                disabled
                                            >
                                                Build processing fee (4%) into
                                                menu pricing.
                                            </Checkbox>
                                        </span>
                                        <span>
                                            <Checkbox
                                                onClick={() => {
                                                    onClick(
                                                        "delivery",
                                                        "delivery_fee"
                                                    );
                                                }}
                                                checked={
                                                    settings
                                                        ? settings.delivery
                                                              .include_service_fee
                                                        : false
                                                }
                                                disabled
                                            >
                                                Build service fee (6%) into menu
                                                pricing.
                                            </Checkbox>
                                        </span>
                                    </div>
                                </TabPane>
                                <TabPane tab="Chowly" key="4">
                                    <Input 
                                        style={{ marginTop: "5px", marginBottom: "20px" }}
                                        maxLength={50} 
                                        placeholder="API Key"
                                        name='api_key'
                                        value={api_key}
                                        disabled={
                                            !chowly_status
                                                ? true
                                                : false
                                        }
                                        onChange={(e) => {setAPIKey(e.target.value)}}
                                    />
                                    <div className='flex-direction-column'>
                                        <label style={{ marginRight: "15px" }}>Enable Chowly: </label>
                                        <Switch
                                            checked={chowly_status}
                                            onChange={setChowlyStatusBtn} 
                                        />
                                    </div>
                                    <div className="flex-direction-column justify-content-flex-end padding-top-30px">
                                        <Button className="main-primary-btn btn-width-130px" type="primary" onClick={saveAPIKey}>
                                        Save
                                        </Button>
                                    </div>
                                </TabPane>
                            </Tabs>
                        ) : (
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Payment Gateway" key="1">
                                    <Form
                                        form={form}
                                        onFinish={submintPaymentSetting}
                                    >
                                        <Form.Item
                                            label="Stripe Account ID"
                                            name="payment_gateway_security_key"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                min={1}
                                                placeholder="Stripe Account ID"
                                                disabled={
                                                    securityKey.length
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Form.Item>
                                    </Form>
                                    {/* Delete Stripe Implementation */}
                                    <div className="flex-direction-column justify-content-flex-end padding-top-30px">
                                        <Button className="main-primary-btn btn-width-130px" type="primary"  onClick={() => {
                                                DialogUtils.confirm(
                                                    "Are you sure you want to delete this stripe account ID? Deleting it will redirect you to the onboarding process.",
                                                    () => {
                                                        onDelete();
                                                    }
                                                );
                                            }}>
                                        Delete
                                        </Button>
                                    </div>
                                </TabPane>
                                <TabPane tab="Chowly" key="2">
                                    <Input 
                                        style={{ marginTop: "5px", marginBottom: "20px" }}
                                        maxLength={50} 
                                        placeholder="API Key"
                                        name='api_key'
                                        value={api_key}
                                        disabled={
                                            !chowly_status
                                                ? true
                                                : false
                                        }
                                        onChange={(e) => {setAPIKey(e.target.value)}}
                                    />
                                    <div className='flex-direction-column'>
                                        <label style={{ marginRight: "15px" }}>Enable Chowly: </label>
                                        <Switch
                                            checked={chowly_status}
                                            onChange={setChowlyStatusBtn} 
                                        />
                                    </div>
                                    <div className="flex-direction-column justify-content-flex-end padding-top-30px">
                                        <Button className="main-primary-btn btn-width-130px" type="primary" onClick={saveAPIKey}>
                                        Save
                                        </Button>
                                    </div>
                                </TabPane>
                            </Tabs>
                        )}
                    </div>
                ) : null}
            </Modal>
        </div>
    );
};

export default SettingsView;
