import React, { useState, useEffect } from "react";
import { Modal, Button,message, Tooltip } from "antd";
import "../less/_main-dir.less";
import config from "./../../../config"; 
import {CopyOutlined} from "@ant-design/icons";
import StoreService from '../../../services/api/RestaurantDetailService';

//redux
import { connect } from "react-redux";
import { printerIp, ipReset } from "../../../utils_redux/actions";

const tooltipText = "Copy Code.";

const GenereateOrderNowView = (props: any) => {

    let storeService = new StoreService();
    const [codeSnippet, setCodeSnippet] = useState("");
    const [restoID, setRestoID] = useState("");
    const [storeSlug, setStreSlug] = useState("");

    useEffect( () =>{
        const initialize = async () => {
            let storeString:string | null = localStorage.getItem('store') ? localStorage.getItem('store') : '{}';
            const storObj = JSON.parse(storeString ? storeString : '');
            const storeID = storObj && storObj._id ? storObj._id : "";
            if(storeID){
                let slug = await storeService.getStoreSlug(storeID);
                setRestoID(storeID);
                setStreSlug(slug)
               let code = `
<container>
    <style type="text/css">
        .image {
            height: 30px; 
            padding-right: 8px;
        }
        .btn{
            background: #fdb415;
            color: #ffffff; 
            padding: 10px 12px; 
            font-weight: 600; 
            font-size: 16px; 
            text-decoration: none; 
            display: inline-flex;
            align-items: center;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
        }
        .btn:hover {
            background: #ffc83d;
        }
    </style>
    <a href="`+ config.CUSTOMER_ENDPOINT+ `restaurants/` + slug+ `" target="_blank"; class="btn">
        <img src="` + config.LOGO +`" alt="Hard Liquors" class="image">
        Order Now
    </a>
</container>
               `;
               setCodeSnippet(code);
            }
        }
        initialize();
    }, []);

    const onCancel = () => {
        props.onCancel();
    };

    const onClickCopyToClipboard = () => {
        
        navigator.clipboard.writeText(codeSnippet)
        message.success('Copied!');
        // window.clipboardData.setData("Text", 'Copy this text to clipboard')
    }

    return (
        <div>
            <Modal
                forceRender
                title="Generate Order Now"
                visible={props.visible}
                centered
                onCancel={onCancel}
                width={550}
                footer={false}
                maskClosable={false}
            >
                <div className="flex_end">
                    <Tooltip title={tooltipText} placement="topRight">
                        <Button htmlType="submit"
                            className="primary-btn font-size-24px padding-top-5px"
                            onClick={onClickCopyToClipboard}
                        >
                            <CopyOutlined/>
                        </Button>
                    </Tooltip>
                </div>
                <pre className="margin-top-66px">
                    {codeSnippet}
                </pre>

                <div className="margin-top-20px font-width-600"> Example Button View</div>
                <div className="generate_btn">
                    <a href={config.CUSTOMER_ENDPOINT + `restaurants/` + storeSlug} target="_blank" className="btn">
                        <img src={config.LOGO} alt="Hard Liquors" className="image"/>
                        Order Now
                    </a>
                </div>
            </Modal>
        </div>
    );
};

export default connect(null, { printerIp, ipReset })(GenereateOrderNowView);
