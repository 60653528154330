import { Button, Form } from "antd";
import React, { useState, useEffect } from "react";
//style
import './less/_onboarding_account.less'
//service
import OnboardingCustomerService from '../../services/api/OnboardingCustomerService';
import DialogUtils from "../../utils/DialogUtils";
//icon
import logo from '../../assets/img/logo.jpg'

const ReauthOnBoardingAccount = (props:any) => {
    const onboardingCustomerService = new OnboardingCustomerService();
    useEffect (()=>{
        document.body.style.backgroundColor = "white";
        checkHasOnboarding();
    },[])

    const checkHasOnboarding = async() => {
        let result = await onboardingCustomerService.checkHasOnboarding();
        if(result && result.details && result.details.hasOnboarding){
            let userString: string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
            const userObj = JSON.parse(userString ? userString : '');
            userObj.onboardingAccounts = result.details.onboarding_id;
            await localStorage.setItem('user', JSON.stringify(userObj));
            props.history.push('/restaurant_detail')
        }else{
            create();
        }
    }

    const cancel = async() => {
        localStorage.removeItem("user");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("store");
        window.location.href = "/";
    }

    const create = async() => {
        let result = await onboardingCustomerService.createLink();
        if(result && result.details && result.details.url){
            window.location.href = result.details.url;
        }else{
            DialogUtils.error('Something went wrong while creating the onboarding link. Please try again.')
        }
    }

    const existingAccount = async () => {
        let result = await onboardingCustomerService.createLinkExistingAccount();
        if(result){
            window.location.href = result;
        }else{
            DialogUtils.error('Something went wrong while creating the onboarding link. Please try again.')
        }
    }


    return (
        <div className="onboarding_main_div">
        <div
          className="onboarding_card"
          style={{ textAlign: "left" }}
        > 
            <img src={logo} alt={"appetit"} className="onboarding_image" />
            <div className="onboarding_card_title">Merchant Onboarding</div>
            <div className="onboarding_card_body">You have no connected stripe account. Please create your account to allow your customers to order.</div>
            <Button className="main-primary-btn btn-width-100p" onClick={existingAccount}>EXISTING STRIPE ACCOUNT</Button>
            <Button style={{"marginTop": 15}} className="main-primary-btn btn-width-100p" onClick={create}>NEW STRIPE ACCOUNT</Button>
            <Button className="main-primary-btn btn-width-100p" style={{"marginTop": 15}} onClick={cancel} disabled>CANCEL</Button>
        </div>
      </div>
    );
};

export default ReauthOnBoardingAccount;
