import React from "react";

//order merchant
export const orderDetails = (orderDetail: any) => async (dispatch: any) => {
    dispatch({ type: "SEND_ORDER_DETAILS", payload: orderDetail });
};

export const orderReset = () => {
    return { type: "RESET" };
};

//printer settings

export const printerIp = (ip: any) => async (dispatch: any) => {
    dispatch({ type: "SEND_IP_ADDRESS", payload: ip });
};

export const ipReset = () => {
    return { type: "RESETIP" };
};
