import { Button, Form } from "antd";
import React, { useState, useEffect } from "react";
//style
import './less/_onboarding_account.less'
//service
import OnboardingCustomerService from '../../services/api/OnboardingCustomerService';
import DialogUtils from "../../utils/DialogUtils";
//icon
import logo from '../../assets/img/logo.jpg';


const CreateOnBoardingAccount = (props:any) => {
    const onboardingCustomerService = new OnboardingCustomerService();
    let [isButtonDisabled, setIsButtonDisabled] = useState(false);
    useEffect (()=>{
        document.body.style.backgroundColor = "white";
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.search);
        const state = params.get('state');
        const code = params.get('code');
        if(state && code){
            setIsButtonDisabled(true);
            linkAccount(code, state);
        }else{
            checkHasOnboarding();
        }
    },[])

    const errorMessage = () => {
        return <>
        <div style={{ padding: '20px', textAlign: "left" }}>
            <p>
                It looks like your Stripe account is currently paused for charges and payouts. This usually happens due to pending verification or compliance requirements.
            </p>
            <p><strong>To continue connecting to our platform, please take the following steps:</strong></p>
            <ol>
                <li>
                    <strong>Log in to Your Stripe Dashboard:</strong><br />
                    Visit <a href="https://dashboard.stripe.com/" target="_blank" rel="noopener noreferrer">Stripe Dashboard</a> and log in with your credentials.
                </li>
                <li>
                    <strong>Review and Complete Any Required Updates:</strong><br />
                    Check for any notifications or messages indicating why charges and payouts are paused.<br />
                    Ensure all required information and documents are provided, including business details, tax information, and bank account details.
                </li>
            <li>
                <strong>Verify Compliance Requirements:</strong><br />
                Complete any additional compliance steps or document requests Stripe might require.
            </li>
        <li>
       <strong>Address Any Holds or Restrictions:</strong><br />
       Follow any provided instructions to lift holds or restrictions on your account.
        </li>
     </ol>
    <p>
        Once you’ve addressed these issues and your account status is updated, you should be able to connect to our platform seamlessly.
    </p>
    </div>
    </>
    }
    const linkAccount = async(code:string, state:string)=>{
       
        let result = await onboardingCustomerService.linkExistingAccount(code, state).catch(()=> {
            setIsButtonDisabled(false);
        });
        if(result && result.hasOnboarding === true){
            let userString: string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
            const userObj = JSON.parse(userString ? userString : '');
            userObj.onboardingAccounts = result.onboarding_id;
            await localStorage.setItem('user', JSON.stringify(userObj));
            props.history.push('/restaurant_detail')
        }else{
            DialogUtils.error(errorMessage(), isFailedLinking)
        }
    }
    const isFailedLinking = () => {
        setIsButtonDisabled(false);
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.search = ''
        const urlWithoutParams = url.toString();
        window.location.href = urlWithoutParams;
    }
    const checkHasOnboarding = async() => {
        let result = await onboardingCustomerService.checkHasOnboarding();
        if(result && result.details && result.details.hasOnboarding){
            let userString: string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
            const userObj = JSON.parse(userString ? userString : '');
            userObj.onboardingAccounts = result.details.onboarding_id;
            await localStorage.setItem('user', JSON.stringify(userObj));
            props.history.push('/restaurant_detail')
        }
    }

    const create = async() => {
        setIsButtonDisabled(true)
        let result = await onboardingCustomerService.createLink();
        if(result && result.details && result.details.url){
            window.location.href = result.details.url;
        }else{
            setIsButtonDisabled(false);
            DialogUtils.error('Something went wrong while creating the onboarding link. Please try again.')
        }
    }

    const existingAccount = async () => {
        let result = await onboardingCustomerService.createLinkExistingAccount();
        if(result){
            window.location.href = result;
        }else{
            DialogUtils.error('Something went wrong while creating the onboarding link. Please try again.')
        }
    }

    const cancel = async() => {
        localStorage.removeItem("user");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("store");
        window.location.href = "/";
    }

    return (
        <div className="onboarding_main_div">
        <div
          className="onboarding_card"
          style={{ textAlign: "left" }}
        >   
            <img src={logo} alt={"appetit"} className="onboarding_image" />
            <div className="onboarding_card_title">Merchant Onboarding</div>
            <div className="onboarding_card_body">You have no connected stripe account. Please create your account to allow your customers to order.</div>
            <Button disabled={isButtonDisabled} className="main-primary-btn btn-width-100p" onClick={existingAccount}>EXISTING STRIPE ACCOUNT</Button>
            <Button disabled={isButtonDisabled} style={{"marginTop": 15}} className="main-primary-btn btn-width-100p" onClick={create}>NEW STRIPE ACCOUNT</Button>
            <Button className=" btn-width-100p" style={{"marginTop": 15}} onClick={cancel}>CANCEL</Button>
        </div>
      </div>
    );
};

export default CreateOnBoardingAccount;
