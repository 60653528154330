import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Switch, Form } from "antd";
import DialogUtils from "../../../utils/DialogUtils";
import ValidatorUtils from "../../../utils/ValidatorUtils";
import "../less/_main-dir.less";

//redux
import { connect } from "react-redux";
import { printerIp, ipReset } from "../../../utils_redux/actions";

// SERVICE
import SettingsService from "../../../services/api/SettingsService";

const PrinterSettingsView = (props: any) => {
    let [form] = Form.useForm();
    const [bluetooth, setBluetooth] = useState(true);
    const [value, setValue] = useState<any>(null);
    const [settings_id, setSettingsID] = useState<any>();
    const settingService = new SettingsService();

    useEffect(() => {
        const initialize = async () => {
            if(props.visible){
                let result = await settingService.getSettings();
                if(result._id){
                    setSettingsID(result._id);
                }
                if(result.printer_settings && result.printer_settings.ip){
                    setBluetooth(false);
                    form.setFieldsValue({
                        ip: result.printer_settings.ip
                    })
                }else{
                    setBluetooth(true);
                }
            }
        }
        initialize();
    }, [props.visible]);

    const onCancel = () => {
        form.resetFields();
        props.onCancel();
    };

    const onSubmitSuccess = async () => {
        let settings = await form.validateFields();
       
        if(settings_id){
            await settingService.editPrinterSettings( settings, settings_id );
            props.onCancel();
        }
        
    };

    const handleBluetoothChange = async (bluetooth: any) => {
        if (bluetooth!) {
            if(settings_id){
                let settings = {
                    ip: ''
                }
                await settingService.editPrinterSettings( settings, settings_id );
            }
        }
        form.resetFields();
        setBluetooth(bluetooth);
    };

    const onChange = (evt: any) => {
        setValue(evt.target.value);
    };

    return (
        <div>
            <Modal
                forceRender
                title="Printer Settings"
                visible={props.visible}
                centered
                className="form_modal"
                onCancel={onCancel}
                width={550}
                footer={false}
                maskClosable={false}
            >
                <div>
                    <label style={{ marginRight: "5px" }}>Bluetooth: </label>
                    <Switch
                        size="default"
                        checked={bluetooth}
                        onChange={handleBluetoothChange}
                    />
                    <div className="print_main_div">
                        <Form form={form} onFinish={onSubmitSuccess} className="print_main_div">
                        
                                        <Form.Item
                                            name="ip"
                                            rules={[
                                                { required: true, message: 'IP Address is required.' },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        if (value && !ValidatorUtils.checkIPAddress(value)) {
                                                            return Promise.reject(
                                                                "Invalid Data."
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            className="form-item-bottom"
                                        >
                                            <Input
                                                className="printer_input"
                                                placeholder="Printer's IP Address: eg. 192.168.22.10"
                                                disabled={bluetooth}
                                                style={{ marginTop: "10px" }}
                                                onChange={onChange}
                                                value={value}
                                                maxLength={20}
                                            />
                                        </Form.Item>
                                        <Form.Item className="form-item-bottom">
                                            <Button htmlType="submit"
                                                className="antd-btn antd-btn-primary printer_button"
                                                disabled={bluetooth} 
                                            >
                                                Apply
                                            </Button>
                                        </Form.Item>
                        </Form>
                        {/* <Input
                            className="input_ip"
                            placeholder="Printer's IP Address: eg. 192.168.22.10"
                            disabled={bluetooth}
                            style={{ marginTop: "10px" }}
                            onChange={onChange}
                            value={value}
                            maxLength={20}
                        /> */}

                        
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default connect(null, { printerIp, ipReset })(PrinterSettingsView);
