import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({component:Component, ...rest}:any) => {
    return (
        <Route
            {...rest}
            render={props => 
                {
                    if(localStorage.getItem('access_token')) {
                        let userString: string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
                        const userObj = JSON.parse(userString ? userString : '');
                        if(userObj.onboardingAccounts && userObj.onboardingAccounts.length){
                            return <Component {...props} />
                        }else{
                            if(window.location.pathname.includes('/onboarding')){
                                return <Component {...props} />
                            }else{
                                return <Redirect to={
                                    {
                                        pathname:'/onboarding',
                                        state:{},
                                    }
                                }/>
                            }
                            
                        }
                    }
                    else{
                        return <Redirect to={
                            {
                                pathname:'/login',
                                state:{},
                            }
                        }/>
                    }
                }
            }
        />

    );
}

export default ProtectedRoute;